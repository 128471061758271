import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { TitleProps } from "../models/types/TitleProps.type";

import "../styles/components/Title.scss";

export default function Title(props: TitleProps) {
  const { ruta, titulo } = props;
  return (
    <>
      {!!ruta ? (
        <>
          <Row className="title">
            <Col className="d-flex aling-items-center" xs={12} sm={12} md={12} lg={12}>
              <Link className="title__regresar" to={ruta}>
                <ArrowBackIcon />
                <Typography variant="body1" fontWeight="500">
                  Regresar
                </Typography>
              </Link>
            </Col>
          </Row>
          <Row>
            <h1 className="tituloEtiqueta">{titulo}</h1>
          </Row>
        </>
      ) : (
        <h1 className="tituloEtiqueta">{titulo}</h1>
      )}
    </>
  )
}
