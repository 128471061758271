import { IAyuda } from "../models/interfaces/IAyuda";

export const ayudaData: IAyuda[] = [  

  //Definir Objetivos y Tiempos
  {
    titulo:(
      <p>
        <strong>Definir Objetivos y Tiempos</strong>
      </p>
    ),
    texto: (
      <p>
        Metas claras y tiempos específicos con minimas desviaciones.
      </p>
    ),
    idAyuda: 1,
  },  

  //Sincronización y Adaptación
  {
    titulo:(
      <p>
        <strong>Sincronización y Adaptación</strong>
      </p>
    ),
    texto: (
      <p>
        Revisamos diariamente con nuestros clientes y el equipo designado el progreso de proyecto, lo que nos permite realizar las adaptaciones necesarias. 
      </p>
    ),
    idAyuda: 2,
    row: 'row',
  },  

  //Desarrollo Incremental
  {
    titulo:(
      <p>
        <strong>Desarrollo Incremental</strong>
      </p>
    ),
    texto: (
      <p>
        Resultados de forma gradual a lo largo del proceso. 
      </p>
    ),
    idAyuda: 3,
  },  

  //Priorización de Valor
  {
    titulo:(
      <p>
        <strong>Priorización de Valor</strong>
      </p>
    ),
    texto: (
      <p>
        Aseguramos las necesidades mas importantes para el cliente.
      </p>
    ),
    idAyuda: 4,
  }, 

  //Resultados Comprobables
  {
    titulo:(
      <p>
        <strong>Resultados Comprobables</strong>
      </p>
    ),
    texto: (
      <p>
        Resultados reales al momento, permite evaluar y tomar decisiones para continuar. 
      </p>
    ),
    idAyuda: 5,
  }, 
];
