import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import { Container, Image, Navbar, Nav } from "react-bootstrap";

import { ANCLAS, ROUTES } from "../../constants/routes";

import hero from "../../assets/images/hero/hero.jpg";
import hero1 from "../../assets/images/hero/hero1.jpg";
import hero2 from "../../assets/images/hero/hero2.jpg";
import hero3 from "../../assets/images/hero/hero3.jpg";
import mswTags from "../../assets/images/mswTags.png";
import dia from "../../assets/images/dia.svg";
import noche from "../../assets/images/noche.svg";

import "../../styles/components/HeaderMain.scss";

interface HeaderMainProps {
  modoNoche: boolean;
  setModoNoche: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HeaderMain({ modoNoche, setModoNoche }: HeaderMainProps) {
  const [navbar, setNavbar] = useState(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

  const changeBackground = () => {
    setNavbar(window.scrollY >= 50);
  };

  const toggleModo = () => {
    setModoNoche(!modoNoche);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Este useEffect observa los cambios en modoNoche
    // y actúa en consecuencia
    if (modoNoche) {
      // Configura el modo nocturno
      document.body.classList.add('modo-noche');
    } else {
      // Configura el modo día
      document.body.classList.remove('modo-noche');
    }
  }, [modoNoche]);

  return (
    <>
      <Navbar
        className={`navmenu ${navbar ? "scroll" : ""} px-3`}
        expand="md"
        variant="dark"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid>
          <NavLink to={ROUTES.ROOT}>
            <Image
              src={mswTags}
              className='logo'
              alt="Logo"
              width={100}
              height={62}
            />
          </NavLink>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navmenu__nav-items">
              <Link
                href={ANCLAS.SERVICIOS}
                color="inherit"
                className='nav-link servicio'
              >
                Servicios
              </Link>
              <Link
                href={ANCLAS.TECNOLOGIAS}
                color="inherit"
                className='nav-link servicio'
              >
                Tecnologías
              </Link>   
              <Link
                href={ANCLAS.NOSOTROS}
                color="inherit"
                className='nav-link servicio'
              >
                Nosotros
              </Link>
              <Link
                href={ANCLAS.EQUIPO}
                color="inherit"
                className='nav-link servicio'
              >
                Equipo
              </Link> 
              <Link
                href={ANCLAS.OFERTA}
                color="inherit"
                className='nav-link servicio'
              >
                Oferta de trabajo
              </Link> 
              <button onClick={toggleModo} className='nav-link'>
                {modoNoche ? (
                  <Image src={noche} width="100%" height="100%" alt='noche'/>
                ) : (
                  <Image src={dia} width="100%" height="100%" alt='dia'/>
                )}
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ position: 'relative' }}>
        {screenWidth > 1300 ? (
          <Image src={hero} className='hero' alt='Logo' width="100%" height="100%"/>
        ) : screenWidth <= 992 && screenWidth > 526 ? (
          <Image src={hero2} className='hero' alt='Logo' width="100%" height="100%"/>
        ) : screenWidth <= 526 ? (
          <Image src={hero3} className='hero' alt='Logo' width="100%" height="100%"/>
        ) : (
          <Image src={hero1} className='hero' alt='Logo' width="100%" height="100%"/>
        )}
        <span style={{ position: 'absolute', bottom: 0, right: '10%', fontWeight: '300', fontSize: '0.750rem', color: 'white' }}>
          Foto de <a href="https://unsplash.com/es/@anasalshanti?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Anas Alshanti</a> en <a href="https://unsplash.com/es/fotos/fotografia-de-enfoque-del-teclado-de-la-computadora-con-luces-rojas-feXpdV001o4" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Unsplash</a>
        </span>
      </div>
    </>
  );
};