import { IOferta } from "../models/interfaces/IOferta";

import fullstack from "../assets/images/vacantes/FullStack.png";
import uiux from "../assets/images/vacantes/UIUX.png";
import qa from "../assets/images/vacantes/QA.png";
import senior from "../assets/images/vacantes/Senior.png";

export const ofertaData: IOferta[] = [  

  //FullStack
  {
    idOferta: 1,
    row: 'row',
    src: `${fullstack}`,
  }, 
  
  //UI/UX
  {
    idOferta: 2,
    src: `${uiux}`,
  },

  //Senior BackEnd
  {
    idOferta: 3,
    row: 'row',
    src: `${senior}`,
  }, 

  //QA
  {
    idOferta: 4,
    src: `${qa}`,
  },
];
