import { Card, Col, Row, Image } from "react-bootstrap";

import { ITecnologias } from "../models/interfaces/ITecnologias";

import reactNative from "../assets/images/tecnologias/reactNativeIcon.svg";
import reactJS from "../assets/images/tecnologias/reactJSIcon.svg";
import Net from "../assets/images/tecnologias/NETIcon.svg";
import figma from "../assets/images/tecnologias/figmaIcon.svg";

export const tecnologiasData: ITecnologias[] = [     

  //.Net
  {
    idTecnologia: 1,
    style: "net",
    texto: (
      <>
        <Row className="alturaServicios">
          <Col lg={6} xl={6} xxl={6} className="pt-2 d-flex align-items-end link order-2 order-lg-1">
            <span>
              Foto de <a href="https://unsplash.com/es/@sigmund?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noopener noreferrer">Sigmund</a> en <a href="https://unsplash.com/es/fotos/gente-sentada-en-una-silla-frente-al-monitor-de-la-computadora-Fa9b57hffnM" target="_blank" rel="noopener noreferrer">Unsplash</a>
            </span>
          </Col>
          <Col lg={6} xl={6} xxl={6} className="textoServicios order-1 order-lg-2">
            <Row>
              <Card.Text
                className="text-justify titulo"
                style={{ fontWeight: "bold" }}
              >
                .NET
              </Card.Text>
            </Row>
            <Row>
              <Card.Text className="text-justify texto">
                ".NET es un framework de desarrollo de software creado por Microsoft. Ofrece un entorno unificado para crear aplicaciones en diversos tipos de plataformas, incluyendo aplicaciones de escritorio, aplicaciones web, aplicaciones móviles y servicios en la nube. .NET es conocido por su robustez, seguridad y capacidad de escalabilidad. Permite a los desarrolladores escribir código en varios lenguajes, como C#, y ofrece una amplia gama de herramientas y bibliotecas para simplificar el desarrollo de aplicaciones."
              </Card.Text>
            </Row>
            <Row className="justify-content-end mt-1">
              <Image src={Net} width={"100%"} height={"100%"} alt=".NET"/>
            </Row>
          </Col>
        </Row>        
      </>
    ),
  },

  //Figma
  {
    idTecnologia: 2,
    style: "figma",
    texto: (
      <>
        <Row className="alturaServicios">
          <Col lg={6} xl={6} xxl={6} className="textoServicios order-2 order-lg-1">
            <Row>
              <Card.Text
                className="text-justify titulo"
                style={{ fontWeight: "bold" }}
              >
                Figma
              </Card.Text>
            </Row>
            <Row>
              <Card.Text className="text-justify texto">
                "Figma es una plataforma de diseño y prototipado colaborativa basada en la nube. Permite a equipos de diseño crear, editar y compartir diseños de interfaces de usuario de forma simultánea, lo que facilita la colaboración en tiempo real. Figma es ampliamente utilizado en el diseño de aplicaciones web y móviles, ya que ofrece herramientas intuitivas para crear prototipos interactivos y permite la fácil transferencia de diseños a desarrolladores."
              </Card.Text>
            </Row>
            <Row className="justify-content-end mt-1">
              <Image src={figma} width={"100%"} height={"100%"} alt="Figma"/>
            </Row>
          </Col>
          <Col lg={6} xl={6} xxl={6} className="pt-2 d-flex align-items-end justify-content-end link order-2">
            <span>
              Foto de <a href="https://unsplash.com/es/@buudkaanaa?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noopener noreferrer">Budka.Damdinsuren</a> en <a href="https://unsplash.com/es/fotos/una-mujer-esta-mirando-la-pantalla-de-una-computadora-xPjsMamUBK4" target="_blank" rel="noopener noreferrer">Unsplash</a>
            </span>
          </Col>
        </Row>
      </>
    ),
  },

  //React JS
  {
    idTecnologia: 3,
    style: "js",
    texto: (
      <>
        <Row className="alturaServicios">
          <Col lg={6} xl={6} xxl={6} className="pt-2 d-flex align-items-end link order-2 order-lg-1">
            <span>
              Foto de <a href="https://unsplash.com/es/@domenicoloia?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noopener noreferrer">Domenico Loia</a> en <a href="https://unsplash.com/es/fotos/imac-plateado-cerca-del-iphone-en-una-mesa-de-madera-marron-EhTcC9sYXsw" target="_blank" rel="noopener noreferrer">Unsplash</a>
            </span>
          </Col>
          <Col lg={6} xl={6} xxl={6} className="textoServicios order-1 order-lg-2">
            <Row>
              <Card.Text
                className="text-justify titulo"
                style={{ fontWeight: "bold" }}
              >
                React JS
              </Card.Text>
            </Row>
            <Row>
              <Card.Text className="text-justify texto">
                "React.js es una popular biblioteca de JavaScript para construir interfaces de usuario interactivas y reactivas. Desarrollada por Facebook, React simplifica la creación de componentes reutilizables que gestionan la vista de una aplicación web. Su enfoque en el rendimiento y la facilidad de mantenimiento lo ha convertido en una elección común para desarrolladores web. React utiliza un modelo de programación declarativo y se integra bien con otras tecnologías y bibliotecas, lo que lo hace ampliamente adoptado en el desarrollo web moderno."
              </Card.Text>
            </Row>
            <Row className="justify-content-end mt-1">
              <Image src={reactJS} width={"100%"} height={"100%"} alt="React JS"/>
            </Row>
          </Col> 
        </Row>       
      </>
    ),
  },

  //React Native
  {
    idTecnologia: 4,
    style: "native",
    texto: (
      <>
        <Row className="alturaServicios">
          <Col lg={6} xl={6} xxl={6} className="textoServicios order-2 order-lg-1">
            <Row>
              <Card.Text
                className="text-justify titulo"
                style={{ fontWeight: "bold" }}
              >
                React Native
              </Card.Text>
            </Row>
            <Row>
              <Card.Text className="text-justify texto">
                "React Native es un framework de desarrollo de aplicaciones móviles que permite a los desarrolladores crear aplicaciones para iOS y Android utilizando JavaScript y React. Ofrece ventajas de eficiencia y reutilización de código al permitir la creación de aplicaciones nativas con una base de código compartido, lo que acelera el desarrollo y reduce costos. React Native es ampliamente utilizado en la industria para crear aplicaciones móviles de alto rendimiento y calidad visual."
              </Card.Text>
            </Row>
            <Row className="justify-content-end mt-1">
              <Image src={reactNative} width={"100%"} height={"100%"} alt="React Native"/>
            </Row>
          </Col>
          <Col lg={6} xl={6} xxl={6} className="pt-2 d-flex align-items-end justify-content-end link order-2">
            <span>
              Foto de <a href="https://unsplash.com/es/@gilleslambert?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noopener noreferrer">Gilles.Lambert</a> en <a href="https://unsplash.com/es/fotos/foto-de-silueta-de-persona-con-smartphone-pb_lF8VWaPU" target="_blank" rel="noopener noreferrer">Unsplash</a>
            </span>
          </Col>
        </Row>
      </>
    ),
  },
];
