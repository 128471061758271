import { Col, Row, Image } from "react-bootstrap";

import { IExperiencia } from "../models/interfaces/IExperiencia";

import done from "../assets/images/done.svg";

export const experienciaData: IExperiencia[] = [  

  //Sector Minero
  {
    titulo:(
      <p>
        <strong>Sector Minero</strong>
      </p>
    ),
    texto: (
      <>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>Soluciones en administración de procesos</span>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>Gestión y cálculo de Inventario</span>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>QA/QC del mineral</span>
          </Col>
        </Row>
      </>
    ),
    idExperiencia: 1,
  },  
  
  //Industría de la Construcción
  {
    titulo:(
      <p>
        <strong>Industria de la Construcción</strong>
      </p>
    ),
    texto: (
      <>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>Presupuestación de Obra: Análisis de Precios Unitario</span>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>Control de Personal</span>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>Documentación de Obra</span>
          </Col>
        </Row>
      </>
    ),
    idExperiencia: 2,
  },  

  //Social
  {
    titulo:(
      <p>
        <strong>Sector Social</strong>
      </p>
    ),
    texto: (
      <>
        <Row className="d-flex align-items-center">
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image src={done} width={30} height={30} alt="Marca de experiencia"/>
          </Col>
          <Col lg={11} md={11} sm={11} xs={11} className="ps-2">
            <span>Registro de población a causas sociales</span>
          </Col>
        </Row>
      </>
    ),
    idExperiencia: 3,
  },  
];
