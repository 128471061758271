import { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import { empleadoData } from "../../constants/empleadoData";
import { IEmpleado } from "../../models/interfaces/IEmpleado";

import "../../styles/components/Empleado.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Equipo({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (
    <>
      <div id="equipo" style={{paddingTop: "5rem", backgroundColor: `${modoNoche ? "black" : "white"}`}}/>
      <section>
        <motion.div
          className="box Equipo"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}
        >
          <Container>
            <div className="text-justify">
              <h1 className="tituloEtiqueta">
                Conoce a nuestro <span style={{ color: "#33BFF0" }}>talentoso</span>{" "}
                equipo
              </h1>
              <p className="pt-3" style={{fontSize: '1.125rem', fontWeight: '400'}}>
                Apasionados por la tecnología y dedicado a convertir desafíos en
                soluciones. ¡Juntos, hacemos posible lo imposible!
              </p>
            </div>

            <Row className="mt-5" xl="5" lg="4" md="3" sm="2" xs="1">
              {empleadoData.map((data: IEmpleado) => (
                <Col className="img" key={data.id}>
                  <Image
                    src={data.src}
                    alt={data.alt}
                    width={200}
                    height={200}
                    className="imgOriginal"
                  />
                  <h3 className="nombre">{data.nombreCompleto}</h3>
                  <h4 className="texto mb-4">{data.titulo}</h4>
                </Col>
              ))}
            </Row>
          </Container>
        </motion.div>
      </section>
    </>
  );
}
