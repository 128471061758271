import { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../Title";

import laptop from "../../assets/images/laptop.jpg";

import "../../styles/components/Experiencia.scss";
import { experienciaData } from "../../constants/experienciaData";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Experiencia() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);  

  
  return (
    <motion.div 
      className="box Experiencia"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}>
      <Container className="pt-5">        
        <div className="text-justify">
          <Title titulo="Experiencia en diversos sectores de la industria" />  
          <Row>
            <Col className="p-3" xl={6} lg={6}>
              <Image src={laptop} width={"100%"} height={"95%"} alt="Foto de Wesson Wang"/>
              <span style={{fontWeight: '300', fontSize: '0.750rem'}}>
                Foto de <a href="https://unsplash.com/es/@wesson?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Wesson Wang</a> en <a href="https://unsplash.com/es/fotos/macbook-y0_vFxOHayg" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Unsplash</a>
              </span>
            </Col>
            <Col xl={6} lg={6} className="experiencias p-3">
              <Row>
                <Col>
                  {experienciaData.map((data) => (
                    <div className="pt-3">
                      <Row>
                      <div className="titulo">{data.titulo}</div>
                      </Row>
                      <Row>
                        <div className="texto">{data.texto}</div>
                      </Row>
                    </div>
                  ))}
                </Col>
              </Row>                   
            </Col>
          </Row>       
        </div>
      </Container>
    </motion.div>
  );
}