import { IEmpleado } from "../models/interfaces/IEmpleado";

import carlos from "../assets/images/trabajadores/licenciado.jpg";
import polo from "../assets/images/trabajadores/polo.jpg";
import rudy from "../assets/images/trabajadores/rudy.jpg";
import isis from "../assets/images/trabajadores/isis.jpg";
import elsy from "../assets/images/trabajadores/elsy.jpg";
import itzel from "../assets/images/trabajadores/itzel.jpg";
import chuy from "../assets/images/trabajadores/chuy.jpg";
import wences from "../assets/images/trabajadores/wences.jpg";
import ray from "../assets/images/trabajadores/ray.jpg";
import daniel from "../assets/images/trabajadores/daniel.jpg";
import francisco from "../assets/images/trabajadores/francisco.jpg";
import jose from "../assets/images/trabajadores/jose.jpg";
import osvaldo from "../assets/images/trabajadores/osvaldo.jpg";
import caro from "../assets/images/trabajadores/caro.jpg";
import axel from "../assets/images/trabajadores/axel.jpg";

export const empleadoData: IEmpleado[] = [
  //Carlos Maldonado
  {
    id: 1,
    src: `${carlos}`,
    alt: "Carlos",
    nombreCompleto: "Carlos Maldonado",
    titulo: "Fundador",
  },
  
  //Polo Maldonado
  {
    id: 2,
    src: `${polo}`,
    alt: "Polo",
    nombreCompleto: "Polo Maldonado",
    titulo: "Director general",
  },

  //Rudy
  {
    id: 3,
    src: `${rudy}`,
    alt: "Rudy",
    nombreCompleto: "Rodolfo Sandoval",
    titulo: "Director de Tecnologías",
  },

  //Rudy
  {
    id: 4,
    src: `${isis}`,
    alt: "Isis",
    nombreCompleto: "Isis Segundo",
    titulo: "Administrador contable",
  },

  //Rudy
  {
    id: 5,
    src: `${elsy}`,
    alt: "Elsy",
    nombreCompleto: "Elsy Bravo",
    titulo: "Administración y recursos humanos",
  },

  //Itzel
  {
    id: 6,
    src: `${itzel}`,
    alt: "Itzel",
    nombreCompleto: "Itzel Reyes",
    titulo: "Proyect Management",
  },

  //Chuy
  {
    id: 7,
    src: `${chuy}`,
    alt: "Chuy",
    nombreCompleto: "Jesús García",
    titulo: "Programador/Full Stack",
  },

  //Wences
  {
    id: 8,
    src: `${wences}`,
    alt: "Wences",
    nombreCompleto: "Wenceslao Reyes",
    titulo: "Programador/Full Stack",
  },

  //Ray
  {
    id: 9,
    src: `${ray}`,
    alt: "Ray",
    nombreCompleto: "Raymundo Ramos",
    titulo: "Programador/Full Stack",
  },

  //Daniel
  {
    id: 10,
    src: `${daniel}`,
    alt: "Daniel",
    nombreCompleto: "Daniel Mendoza",
    titulo: "Programador/Full Stack",
  },

  //Francisco
  {
    id: 11,
    src: `${francisco}`,
    alt: "Francisco",
    nombreCompleto: "Francisco Zuñiga",
    titulo: "Programador/Frontend",
  },

  //Jose
  {
    id: 12,
    src: `${jose}`,
    alt: "Jose",
    nombreCompleto: "José Vazquez",
    titulo: "Programador/Backend",
  },

  //Osvaldo
  {
    id: 13,
    src: `${osvaldo}`,
    alt: "Osvaldo",
    nombreCompleto: "Osvaldo Angulo",
    titulo: "Proyect Management",
  },

  //Caro
  {
    id: 14,
    src: `${caro}`,
    alt: "Caro",
    nombreCompleto: "Carolina Serrano",
    titulo: "Intern/Trainee",
  },

  //Axel
  {
    id: 15,
    src: `${axel}`,
    alt: "Axel",
    nombreCompleto: "Axel Lopez",
    titulo: "Intern/Trainee",
  },
];
