import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import { ayudaData } from "../../constants/ayudaData";
import Title from "../Title";

import "../../styles/components/Ayuda.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Ayuda({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);  

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (
    <>
      <div id="servicios" style={{paddingTop: "5rem", backgroundColor: `${modoNoche ? "black" : "white"}`}}/>
      <section>
        <motion.div 
          className="box Ayuda pb-5"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}
          id="servicios">
          <Container>        
            <div className="text-justify">
              <Title titulo="¿Necesitas ayuda con tu proyecto?" />  
              <p className="pt-4" style={{fontSize: '1rem', fontWeight:'400'}}>
                Trabajamos para ayudar a nuestros clientes en la solución de sus problemas adaptando la tecnología para tomar el camino correcto. 
              </p>
              <Row className="fondos" >
                {ayudaData.map((data) => (
                  <Col className={`fondo ${data.row}`} key={data.idAyuda}>
                    <figcaption>
                      <div className="titulo">{data.titulo}</div>
                      <div className="texto">{data.texto}</div>
                    </figcaption>
                  </Col>
                ))}
              </Row>          
            </div>
          </Container>
        </motion.div>
      </section>
    </>
  );
}