import Ayuda from "../../components/Home/Ayuda";
import Experiencia from "../../components/Home/Experiencia";
import Tecnologias from "../../components/Home/Tecnologias";
import InfoEmpresa from "../../components/Home/InfoEmpresa";
import Empleado from "../../components/Home/Empleado";
import Oferta from "../../components/Home/Oferta";

export default function Home({ modoNoche }) {
  return (
    <div className="content">
      <Ayuda modoNoche={modoNoche}/>
      <Experiencia />
      <Tecnologias modoNoche={modoNoche} />
      <InfoEmpresa modoNoche={modoNoche}/>
      <Empleado modoNoche={modoNoche}/>
      <Oferta modoNoche={modoNoche}/>
    </div>
  );
}
