import { FloatingWhatsApp } from "react-floating-whatsapp";

import logoWhats from "../../assets/images/favicon.svg";

export default function WhatsApp() {
  return (
    <FloatingWhatsApp
      accountName="Desarrollo MSW"
      phoneNumber="+5213121145616"
      darkMode={true}
      chatMessage="Hola, ¿En que podemos ayudarte?"
      statusMessage="Horario de respuesta de 9am - 6pm"
      avatar={logoWhats}
      allowClickAway={true}
      messageDelay={0}
      placeholder="Mensaje"
    />
  );
}
