import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../Title";

import { tecnologiasData } from "../../constants/tecnologiasData";

import "../../styles/components/Tecnologia.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Tecnologias({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);  

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };
  
  return (
    <>
      <div id="teconlogias" style={{paddingTop: "5rem", backgroundColor: `${modoNoche ? "black" : "white"}`}}/>
      <section>
        <motion.div 
          className="box Tecnologias"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}>
          <Container>        
            <div className="text-justify">
              <Title titulo="Tecnologías que manejamos" />  
              {tecnologiasData.map((data, index) => (
                <div className="mt-5" key={index}>
                  <div className={`${data.style} mt-5`}>
                    <Container className="pt-5 pb-5">
                      {data.texto}
                    </Container>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </motion.div>
      </section>
    </>
  );
}