export const carreraData = [
    {
      idCarrera: "informatica",
      label: "Ingeniero en informática",
    },
    {
      idCarrera: "sistemas",
      label: "Ingeniero en sistemas",
    },
    {
      idCarrera: "software",
      label: "Ingeniero en software",
    },
  ];