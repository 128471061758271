export const infoData = [
  //Misión
  {
    titulo: "Misión",
    texto:
      "En nuestra consultora de software, nos comprometemos a crear aplicaciones web y móviles de alta calidad utilizando tecnologías de vanguardia. Buscamos satisfacer las necesidades de nuestros clientes, brindando soluciones eficientes y escalables.",
    idInfo: 1,
  },

  //Visión
  {
    titulo: "Visión",
    texto:
      "Nos esforzamos por ser líderes en el desarrollo de software, innovando constantemente para ofrecer las mejores soluciones tecnológicas. Aspiramos a ser reconocidos por nuestra excelencia en el servicio al cliente y la creación de productos que transformen industrias.",
    idInfo: 2,
  },

  //Valores
  {
    titulo: "Valores",
    texto:
      "Nuestros valores fundamentales incluyen la excelencia técnica, la colaboración, la integridad y la pasión por la tecnología. Nos comprometemos a trabajar en equipo, mantener altos estándares éticos y abrazar el aprendizaje continuo para lograr el éxito sostenible.",
    idInfo: 3,
    row: "row",
  },
];
