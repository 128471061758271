import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import { infoData } from "../../constants/infoData";

import blancoLogo from "../../assets/images/blancoLogo.svg";

import "../../styles/components/InfoEmpresa.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function InfoEmpresa({modoNoche}) {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [maxHeight, setMaxHeight] = useState("auto");

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  useEffect(() => {
    // Función para calcular la altura máxima
    const calculateMaxHeight = () => {
      // Encuentra la altura máxima entre los valoresData
      const maxCardHeight = infoData.reduce((maxHeight, data) => {
        let cardTextHeight = data.texto.length * 1.3;

        // Verificar si la pantalla es mayor a 767px
        if (window.innerWidth > 340) {
          cardTextHeight = data.texto.length * 1.1;
        }

        return cardTextHeight > maxHeight ? cardTextHeight : maxHeight;
      }, 0);

      setMaxHeight(`${maxCardHeight}px`); // Establece la altura máxima en píxeles
    };

    // Llama a calculateMaxHeight() cuando se carga la página
    calculateMaxHeight();

    // Agrega un event listener para el evento 'resize'
    window.addEventListener("resize", calculateMaxHeight);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", calculateMaxHeight);
    };
  }, []);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (
    <>
      <div id="nosotros" style={{paddingTop: "5rem", backgroundColor: `${modoNoche ? "black" : "white"}`}}/>
      <section>
        <motion.div
          className="box infoEmpresa"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}
        >
          <Container>
            <div className="text-justify">
              <p className="tituloHistoria pb-5">
                Nuestra pasión por la innovación y el compromiso con la excelencia ha
                forjado nuestra historia de éxito en la industria.
              </p>

              <Row>
                <Col className="d-flex" xl={5} lg={5}>
                  <Image src={blancoLogo} width={"100%"} height={"100%"} alt="MSW innovación + emprendimiento"/>
                </Col>
                <Col xl={7} lg={7} className="historia">
                  <Row>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <div className="linea-vertical"></div>
                    </Col>
                    <Col>
                      <Row>
                        Somos una empresa Mexicana con mas de 44 años de experiencia
                        generando soluciones tecnológicas enfocadas al sector de
                        informática y telecomunicaciones.
                      </Row>
                      <Row className="mt-3">
                        Nuestra empresa se ha caracterizado por un amplio dinamismo
                        en el manejo de proyectos con un elevado nivel de
                        especialización, así como proyectos de misión crítica donde
                        los tiempos de entrega eran deﬁnitivos.
                      </Row>
                      <Row className="mt-3">
                        La estrategia actual va ligada a la investigación
                        tecnológica trabajando en la mejora continua de los
                        proyectos que tiene la empresa. Contamos con un
                        equipo de profesionales experimentados en diversos campos
                        cientíﬁcos, tecnológicos y del conocimiento, ofreciendo a
                        nuestros clientes conocimiento integrado en innovación y
                        tecnología.
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-3 fondos">
                {infoData.map((data) => (
                  <Col className={`fondo ${data.row}`}>
                    <div key={data.idInfo}>
                      <Card className="m-1">
                        <Card.Body style={{ height: maxHeight }}>
                          <Card.Title className="titulo">{data.titulo}</Card.Title>
                          <Card.Text className="texto">{data.texto}</Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </motion.div>
      </section>
    </>
  );
}
