import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import HeaderMain from "./components/Header/HeaderMain";
import Footer from "./components/Footer/Footer";
import WhatsApp from "./components/WhatsApp/WhatsApp";
import Loading from "./components/Loading/Loading";
import Home from "./pages/Home/Home";
import useLoadingStore from "./store/useLoadingStore ";

import { ROUTES } from "./constants/routes";

import "./App.css";

function App() {
  const [cargar, setCargar] = useState<boolean>(false);
  const [modoNoche, setModoNoche] = useState(true); //True para que la pagina inicie en modo noche o false para que inice en modo día
  const { isLoading } = useLoadingStore();

  useEffect(() => {
    setCargar(true);
    setTimeout(() => {
      setCargar(false);
    }, 500);
  }, []);

  const fondo = () => {
    return {
      minHeight: "100vh",
      backgroundColor: `${modoNoche ? '#000' : '#fff'}`
    };
  };

  return (
    <>
      {cargar && <Loading />}
      {isLoading && <Loading />}
      <main
        className="d-flex flex-column justify-content-between"
        style={fondo()}
      >
        <HeaderMain modoNoche={modoNoche} setModoNoche={setModoNoche}/>
        <div className={`flex-grow-1`}>
            <Routes>
              <Route path={ROUTES.ROOT} element={<Home modoNoche={modoNoche}/>} />
            </Routes>
        </div>
        <WhatsApp />
        <Footer />
      </main>
    </>
  );
}

export default App;
